<template>
  <TitleBar :collapseActions="false" :actions="[]">
    Delivery Detail for
    {{ mailing == null ? "" : mailing.formatted_description }}
  </TitleBar>

  <div class="px-5 pb-5">
    <DeliveryChart
      v-if="trackingDataReady"
      :delivery-data="trackingData"
    ></DeliveryChart>
  </div>
</template>
<script>
import TitleBar from "@/components/TitleBar";
import Mailing from "@/models/Mailing";
import TrackingData from "@/models/TrackingData";
import DeliveryChart from "@/components/delivery/DeliveryChart.vue";
import { useAppStore } from "@/stores/app";

export default {
  components: {
    TitleBar,
    DeliveryChart,
  },
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      mailing: null,
      trackingData: {},
      trackingDataReady: false,
    };
  },
  computed: {
    campaign() {
      return this.appStore.currentCampaign;
    },
    mailingId() {
      return this.$route.params.id;
    },
  },
  async mounted() {
    await Mailing.where("campaign_id", this.campaign.id)
      .where("id", this.mailingId)
      .limit(1)
      .first()
      .then((data) => {
        if (Object.keys(data).length === 0) {
          this.$router.push("/");
          return;
        }
        this.mailing = data;
      });

    await TrackingData.find(this.mailing.id).then((res) => {
      this.trackingData = res.data;
      this.trackingDataReady = true;
    });
  },
};
</script>
