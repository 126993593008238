<template>
  <div class="grid">
    <div class="col-1"></div>
    <div class="col-10">
      <div
        class="p-grid mt-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
      >
        <div class="flex justify-content-between align-items-center mb-1">
          <h2 class="font-normal text-black-alpha-90 my-3">
            Deliveries by Date
          </h2>
          <div></div>
        </div>
        <Chart
          type="line"
          :data="chartData"
          responsive="false"
          width="300"
          height="100"
        />
      </div>
    </div>
  </div>
  <div class="grid">
    <div class="col-2"></div>
    <div class="col-8">
      <div class="mt-5">
        <DataTable
          :value="tableData"
          responsiveLayout="scroll"
          showGridlines
          stripedRows
        >
          <Column field="date" header="Date"></Column>
          <Column field="day_of_week" header="Day"></Column>
          <Column
            field="daily_total"
            header="Delivered"
            bodyStyle="text-align:right"
          ></Column>
          <Column
            field="daily_percent"
            header="Delivered %"
            bodyStyle="text-align:right"
          ></Column>
          <Column
            field="running_total"
            header="Total"
            bodyStyle="text-align:right"
          ></Column>
          <Column
            field="running_total_percent"
            header="Total %"
            bodyStyle="text-align:right"
          ></Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "primevue/chart";
export default {
  components: {
    Chart,
  },
  props: {
    deliveryData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: null,
      tableData: null,
    };
  },
  mounted() {
    this.tableData = this.deliveryData.table;
    this.chartData = {
      labels: this.deliveryData.chart.labels,
      datasets: [
        {
          label: "Delivered",
          data: this.deliveryData.chart.data,
          fill: false,
          borderColor: "#42A5F5",
          tension: 0.4,
          legend: false,
        },
      ],
    };
  },
};
</script>
